import React, { useState } from 'react'
import { Container, Row, Col, Dropdown } from '@mch-group/uikit-components'
import Text from '../Text'
import cssClasses from '../../constants/cssClasses'

// Define the props type
interface H2AProps {
  [key: string]: string
}

interface SelectOption {
  id: number;
  label: string;
  value: string;
}

const buildSelectOptions = (props: H2AProps): SelectOption[] => {
  const values = Array.from({ length: 10 }, (item, i) => {
    const index = i + 1
    if (props[`h2a_grp2_link${index}_label` as keyof H2AProps] && props[`h2a_grp2_link${index}_href` as keyof H2AProps]) {
      return {
        id: index,
        label: props[`h2a_grp2_link${index}_label` as keyof H2AProps] as string,
        value: props[`h2a_grp2_link${index}_href` as keyof H2AProps] as string
      }
    }
    return undefined
  })
  return values.filter((item): item is SelectOption => typeof item !== 'undefined')
}

const H2A: React.FC<H2AProps> = ({ moduleName, ...props }) => {
  const [selectedValue, setSelectedValue] = useState<string>('')

  const handleChange = (e: string) => {
    setSelectedValue(e)
    setTimeout(() => {
      const element = document.getElementById(e)
      if (element) {
        const top = element.offsetTop
        window.scrollTo({
          top: top - 100,
          behavior: 'smooth'
        })
      }
    }, 100)
    blurAllInteractiveElements()
  }

  // Function to blur all interactive elements
  const blurAllInteractiveElements = () => {
    const interactiveElements = document.querySelectorAll(
      'select, [tabindex]:not([tabindex="-1"])'
    )

    interactiveElements.forEach(element => {
      //@ts-ignore
      element.blur()
    })
  // eslint-disable-next-line semi
  };

  return (
    <section id={props.id} className={`${moduleName} ${cssClasses.CMS_MODULE}`}>
      <Container className='px-5 px-lg-8 px-md-7'>
        {props.h2a_grp1_title && (
          <>
            <h2 className='my-7'>{props.h2a_grp1_title}</h2>
            {props.h2a_grp1_desc && (
              <Row gutter='g-0'>
                <Col className='mb-6 col-8'>
                  <Text forwardedAs='span' isHtml className='text-medium text-dark'>
                    {props.h2a_grp1_desc}
                  </Text>
                </Col>
              </Row>
            )}
          </>
        )}
        {props.h2a_select_label && (
          <Row>
            <Col md={4}>
              <Dropdown
                //@ts-ignore
                label={<span className='text-label-xlarge'>{props.h2a_select_label}</span>}
                options={buildSelectOptions(props)}
                placeholder={props.h2a_jump_label || 'Jump to...'}
                //@ts-ignore
                onChange={handleChange}
                value={selectedValue}
                isClearable={false}
              />
            </Col>
          </Row>
        )}
      </Container>
    </section>
  )
}

export default H2A
